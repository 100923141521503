import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from "react-router-dom";
import {Button, Image} from "react-bootstrap";
import html2canvas from "html2canvas";
import btnCerrar from "../../assets/png/cerrar/x_normal.png";
import membrete from "../../assets/png/membrete.png";
import diplomaEtapa1 from "../../assets/png/Diploma1.png";
import diplomaEtapa2 from "../../assets/png/Diploma2.png";
import "./DescargaDiploma.scss";

function DescargaDiploma(props) {

    const params = useParams();
    //console.log(params)

    const [diploma, setDiploma] = useState("");
    const [nombreUsuario, setNombreUsuario] = useState("");

    useEffect(() => {
        //console.log(params.length)
        if(params) {
            const tempNombre = params.nombre.split("-")
            //console.log(tempNombre)

            setDiploma((tempNombre[0]))
            setNombreUsuario(tempNombre[1])
        }
    }, []);

    const cerrarPagina = () => {
        window.open("", "_self").window.close()
    }

    const descargarDiploma = () => {
        const entrada = document.getElementById("diplomaGenerado");

        html2canvas(entrada).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            //console.log(imgData)

            var file = dataURLtoFile(imgData,'Diploma.png');
            //console.log(file);

            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url;
            //console.log(archivo)
            link.setAttribute('download', "Diploma.png");
            document.body.appendChild(link);
            link.click();

            window.open("", "_self").window.close()
        })
    }

    return (
        <>
            <div className="descargaDiplomas">
                <Image src={membrete} className="membrete" />

                <Image src={btnCerrar} className="btnCerrar" onClick={() => {
                    cerrarPagina()
                }} />

                <div className="diplomas" id="diplomaGenerado">
                    {diploma !== "" &&
                    (
                        <Image src={diploma === "etapa1" ? diplomaEtapa1 : diplomaEtapa2} className="diplomaEtapa1y2" />
                    )
                    }

                    {diploma &&
                        (
                            <p className="nombre">
                                {nombreUsuario}
                            </p>
                        )
                    }
                </div>

                {diploma &&
                    (
                        <div className="botonDiploma">
                            <Button
                                className="botonDescarga"
                                onClick={() => {
                                    descargarDiploma()
                            }}>
                                Descarga tú diploma
                            </Button>
                        </div>
                    )
                }

                <br/>
                <br/>
                <br/>

            </div>
        </>
    );
}

function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

export default withRouter(DescargaDiploma);
