/* eslint-disable import/no-anonymous-default-export */
import DescargaDiploma from "../pages/DescargaDiploma";

export default [
    {
        path: "/",
        exact: true,
        page: DescargaDiploma
    },
    {
        path: "/:nombre",
        exact: true,
        page: DescargaDiploma
    },
]
